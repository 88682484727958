import React, { useState, useContext, useEffect } from "react"
import { RichText } from "prismic-reactjs"

import { InteractionsContext } from "@contexts/interactions"

import { injectIntl } from "gatsby-plugin-intl"

import ContactFormProfil from "@components/contact/ContactFormProfil"

import "./ContactForm.scss"

function ContactForm({ intl, edito, visual }) {
  const { addToaster } = useContext(InteractionsContext)

  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    message: "",
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  function resetForm() {
    setFormValues({
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      message: "",
    })
  }

  function handleChange(e) {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })
  }

  function handleSubmit(e) {

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact_form",
        ...formValues,
      }),
    })
      .then(() => {
        addToaster(
          intl.formatMessage({ id: "email_success_title" }),
          intl.formatMessage({ id: "email_success_texte" }),
          "success"
        )
        resetForm()
      })
      .catch(error => {
        addToaster(
          intl.formatMessage({ id: "email_error_title" }),
          intl.formatMessage({ id: "email_error_texte" }),
          "error"
        )
      })

    e.preventDefault()
  }

  return (
    <div className="ContactForm">
      <div className="container">
        <div className="ContactForm-container">
          <form
            name="contact_form"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={e => handleSubmit(e)}
          >
            <div className="edito">{RichText.render(edito)}</div>
            <div className="ContactForm-line ContactForm-line--two">
              <div className="ContactForm-field">
                <label>{intl.formatMessage({ id: "form_firstname" })}</label>
                <input
                  name="firstname"
                  required
                  type="text"
                  value={formValues.firstname}
                  onChange={e => handleChange(e)}
                />
              </div>
              <div className="ContactForm-field">
                <label>{intl.formatMessage({ id: "form_lastname" })}</label>
                <input
                  name="lastname"
                  required
                  type="text"
                  value={formValues.lastname}
                  onChange={e => handleChange(e)}
                />
              </div>
            </div>
            <div className="ContactForm-line ContactForm-line--two">
              <div className="ContactForm-field">
                <label>{intl.formatMessage({ id: "form_email" })}</label>
                <input
                  name="email"
                  type="email"
                  required
                  value={formValues.email}
                  onChange={e => handleChange(e)}
                />
              </div>
              <div className="ContactForm-field">
                <label>{intl.formatMessage({ id: "form_phone" })}</label>
                <input
                  name="phone"
                  required
                  type="tel"
                  value={formValues.phone}
                  onChange={e => handleChange(e)}
                />
              </div>
            </div>
            <div className="ContactForm-line">
              <div className="ContactForm-field">
                <label>{intl.formatMessage({ id: "form_message" })}</label>
                <textarea
                  required
                  name="message"
                  value={formValues.message}
                  onChange={e => handleChange(e)}
                />
              </div>
            </div>
            <div className="ContactForm-button">
              <button type="submit" className="Button ButtonPrimary">
                Envoyer
              </button>
            </div>
          </form>
          <ContactFormProfil visual={visual} />
        </div>
      </div>
    </div>
  )
}

export default injectIntl(ContactForm)
