import React, {useEffect, useState} from "react"

import { injectIntl, Link } from "gatsby-plugin-intl"

import "./Breadcrumb.scss"

import { ROUTES } from "../../../constants/routes"

function Breadcrumb({ path, pageName, intl }) {

  const [parents, setParents] = useState([])

  useEffect(() => {

    const filtered = path.split("/").filter(function (el) {
      return ROUTES.includes(el) && pageName.toLowerCase() !== el.toLowerCase() ;
    });
  
    setParents(filtered)
    
  }, [path]);

  return (
    <div className="Breadcrumb">
      <div className="container">
        <ul>
          <li><Link to="/" className="Breadcrumb-link">{intl.formatMessage({ id: "home" })}</Link></li>
          {parents.map(
            x =>
            <li key={x}><Link to={`/${x}`} className="Breadcrumb-link">{intl.formatMessage({ id: `breadcrumb_${x}` })}</Link></li>
          )}
          <li><span className="Breadcrumb-title">{pageName}</span></li>
        </ul>
    </div>
    </div>
  )
}

export default injectIntl(Breadcrumb)
