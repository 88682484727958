import React from "react"

import "./BannerPage.scss"

function BannerPage({ texte, visual }) {
  return (
    <div className="BannerPage">
      <div className="BannerPage-visual">
        {visual && (
          <picture>
            <source media="(min-width: 1024px)" srcSet={visual.url} />
            <source media="(min-width: 640px)" srcSet={visual?.thumbnails.tablet?.url} />
            <source srcSet={visual?.thumbnails.url} />
            <img src={visual.url} alt={visual.alt} />
          </picture>
        )}
      </div>
      <div className="container">
        <h1>{texte}</h1>
      </div>
    </div>
  )
}

export default BannerPage
