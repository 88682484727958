import React from "react"

import "./ContactForm.scss"

function ContactFormProfil({visual}) {
  return (
    <div className="ContactFormProfil">
      <div className="ContactFormProfil-container">
      <div
        className="ContactFormProfil-img"
        style={{ backgroundImage: `url(${visual.url})` }}
      ></div>
        <div className="edito">
          <p>
            <a href='mailto:contact@laboservices.eu'>contact@laboservices.eu</a>
            <br />
            <a href='tel:+33 9 82 99 06 09'>+33 9 82 99 06 09</a>
            <br />
            <br />7 Allée de Chartres
            <br />
            33000 Bordeaux
            <br />
            FRANCE
          </p>
        </div>
      </div>
    </div>
  )
}

export default ContactFormProfil
